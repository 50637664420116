import React from "react";

function ListItem({ image, title, text }) {
  return (
    <li className="mx-auto mt-5 mb-10 px-5 text-xl md:w-1/3 md:mt-10 md:mb-0 lg:text-2xl ">
      <img
        className="transform scale-75 md:scale-100 md:w-auto mx-auto mb-4 md:mb-7"
        src={image}
      />
      <p className="whitespace-nowrap md:whitespace-normal text-colors-outer-slate">
        {title}
      </p>
      <p className="text-base text-blue-500 mt-2 leading-snug sm:text-xl md:mt-5 md:text-sm lg:text-xl">
        {text}
      </p>
    </li>
  );
}

export default function RTB({ data }) {
  return (
    <div className="content-container-max mt-0 md:mt-10 lg:mt-10 text-blue-900">
      <ul className="md:flex text-center md:-mx-5">
        {data.map((rtb, i) => (
          <ListItem key={i} {...rtb} />
        ))}
      </ul>
    </div>
  );
}
