import * as React from "react";
import Button from "../../components/owner-since/button";
import useScript from "../sales-booking/useScript";
import * as Analytics from "../../utils/analytics";

export default function DesignCtas({
  ctaLight = false,
  hideCaption = false,
  altPrimaryText = false,
}) {
  function trackChatOpen() {
    Analytics.track("Design Chat Started", {
      location: "Page Button",
    });
  }

  const cta1Text = altPrimaryText
    ? "REQUEST A CONSULT"
    : "BOOK A VIRTUAL CONSULT";

  return (
    <div className="">
      <p className="sm:flex justify-center">
        <span className="block mb-5 sm:mb-0 sm:mr-5">
          <Button
            extraClasses={["w-full sm:w-auto"]}
            light={ctaLight}
            to="/design-services/request"
          >
            {cta1Text}
          </Button>
        </span>
      </p>
      {!hideCaption && (
        <p className="text-sm text-center mt-5">
          Free with no obligation to buy
        </p>
      )}
    </div>
  );
}
