import * as React from "react";

import DesignCtas from "./design-ctas";

export default function GetStarted() {
  return (
    <div className="content-container-max lg:mt-36 lg:pt-1.5 text-center">
      <div className="bg-colors-outer-slate py-10 bg-no-repeat mt-14 lg:py-28 relative">
        <div className="content-container text-white-900 relative z-10">
          <h2 className="text-xl sm:text-3xl lg:text-4xl xl:text-6xl w-9/12 mx-auto">
            Get started with your project today
          </h2>
          <p className="text-sm mt-1 sm:text-lg lg:text-2xl md:mt-6 mb-14 ">
            You dream outdoor space is awaiting. We’re here to help.
          </p>
          <DesignCtas ctaLight={true} />
        </div>
      </div>
    </div>
  );
}
