import { graphql, StaticQuery } from "gatsby";
import * as React from "react";
import Img from "gatsby-image";
import ImageGallery from "react-image-gallery";
import ModalVideo from "react-modal-video";
import { gsap } from "gsap";

import arrowRightSvg from "../../images/darkmode/arrow-right.svg";

import "./gallery.scss";

const galleryQuery = graphql`
  query {
    gallery1: file(relativePath: { eq: "design-services/gallery/Image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery2: file(
      relativePath: { eq: "design-services/gallery/Image-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1492) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery3: file(
      relativePath: { eq: "design-services/gallery/Image-2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 896) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery4: file(
      relativePath: { eq: "design-services/gallery/Image-3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1420) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery5: file(
      relativePath: { eq: "design-services/gallery/Image-4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 1492) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gallery6: file(
      relativePath: { eq: "design-services/gallery/Image-5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1540, maxHeight: 1026) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

function setImage(data, i) {
  const { src, srcSet } =
    (data && data[`gallery${i}`].childImageSharp.fluid) || {};
  return { original: src, srcSet };
}

function tiltImage(e) {
  const targetRot =
    (e.clientX - e.target.getBoundingClientRect().left) / e.target.offsetWidth -
    0.5;

  gsap.to(e.target, {
    duration: 0.1,
    rotationY: 10 * targetRot,
    ease: "power1.inOut",
  });
}

function GalleryImage({ onClick, fluidImage }) {
  return (
    <div>
      <div
        className="o-gallery-image cursor-pointer relative opacity-100 hover:opacity-70 duration-500 transition-opacity"
        onClick={onClick}
      >
        <Img fluid={fluidImage} />
      </div>
    </div>
  );
}

function GalleryFlat({ handleImageClick, data }) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    const imageSelector = ref.current.querySelectorAll(".o-gallery-image");

    gsap.set(imageSelector, {
      rotateY: 0,
      transformPerspective: 900,
      transformOrigin: "center center",
      transformStyle: "flat",
    });

    Array.from(imageSelector).forEach((el: any) => {
      el.onmouseenter = function () {
        el.addEventListener("mousemove", tiltImage, true);
      };

      el.onmouseleave = function () {
        el.removeEventListener("mousemove", tiltImage);
        gsap.to(el, {
          delay: 0.1,
          duration: 0.2,
          rotationY: 0,
          ease: "power1.inOut",
        });
      };
    });
  }, []);

  return (
    <div className="o-gallery mt-10 md:mt-20" ref={ref}>
      <GalleryImage
        onClick={() => handleImageClick(0)}
        fluidImage={data.gallery1.childImageSharp.fluid}
      />

      <GalleryImage
        onClick={() => handleImageClick(2)}
        fluidImage={data.gallery4.childImageSharp.fluid}
      />

      <GalleryImage
        onClick={() => handleImageClick(4)}
        fluidImage={data.gallery3.childImageSharp.fluid}
      />

      <GalleryImage
        onClick={() => handleImageClick(1)}
        fluidImage={data.gallery2.childImageSharp.fluid}
      />

      <GalleryImage
        onClick={() => handleImageClick(3)}
        fluidImage={data.gallery5.childImageSharp.fluid}
      />

      <GalleryImage
        onClick={() => handleImageClick(5)}
        fluidImage={data.gallery6.childImageSharp.fluid}
      />
    </div>
  );
}

function GalleryModal({ showGallery, imageItems, setShowGallery }) {
  return (
    <div className="o-gallery-modal">
      <ModalVideo
        isOpen={showGallery !== false}
        onClose={() => setShowGallery(false)}
      >
        <div
          className="image-gallery-wrapper"
          onClick={e => e.stopPropagation()}
        >
          <ImageGallery
            startIndex={showGallery || 0}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
            items={imageItems}
            renderLeftNav={(onClick, disabled) => {
              return (
                <button
                  className="hidden md:block image-gallery-custom-left-nav"
                  disabled={disabled}
                  onClick={onClick}
                >
                  <img src={arrowRightSvg} />
                </button>
              );
            }}
            renderRightNav={(onClick, disabled) => {
              return (
                <button
                  className="hidden md:block image-gallery-custom-right-nav"
                  disabled={disabled}
                  onClick={onClick}
                >
                  <img src={arrowRightSvg} />
                </button>
              );
            }}
          />
        </div>
      </ModalVideo>
    </div>
  );
}

export default function Gallery() {
  const [showGallery, setShowGallery] = React.useState(false);

  return (
    <StaticQuery
      query={galleryQuery}
      render={data => {
        const imageItems = [1, 2, 4, 5, 3, 6].map(i => setImage(data, i));

        function handleImageClick(index) {
          setShowGallery(index);
        }

        return (
          <div className="overflow-hidden">
            <div className="content-container-max mt-24 md:mt-40 text-center">
              <h2 className="tracking-wide sm:tracking-normal text-2xl md:mx-auto md:max-w-xl lg:max-w-5xl lg:px-14 lg:text-6xl">
                We’ve done this a few times
              </h2>
              <p className="mt-3 text-sm sm:text-2xl sm:mt-10 sm:tracking-wide lg:max-w-4xl lg:mx-auto lg:mb-24">
                From minor facelifts to complete remodels, our team of experts
                is standing by to help you create the outdoor space of your
                dreams
              </p>
              <GalleryFlat handleImageClick={handleImageClick} data={data} />
            </div>
            <GalleryModal
              showGallery={showGallery}
              imageItems={imageItems}
              setShowGallery={setShowGallery}
            />
          </div>
        );
      }}
    />
  );
}
