import * as React from "react";

export default function TuneIn() {
  return (
    <div className="bg-blue-100 mx-2 mt-24 px-5 py-10 md:px-20 md:py-36 md:mt-40">
      <div className="content-container text-center">
        <h2 className="tracking-wide sm:tracking-normal text-2xl md:mx-auto md:max-w-xl lg:max-w-5xl lg:px-14 lg:text-6xl">
          Tune in for design tips and tricks
        </h2>
        <p className="mt-3 text-sm sm:text-2xl sm:mt-10 sm:tracking-wide lg:mx-auto">
          Check out our live streams featuring Outer's design experts and guests
        </p>
        <div className="aspect-video mt-10">
          <iframe
            src="https://www.youtube.com/embed/EIQZ5TOCYBI?start=1940"
            title="Outer Design Services"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
