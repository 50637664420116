import * as React from "react";
import { graphql } from "gatsby";

import Hero from "../../components/design-services/hero";
import Layout from "../../components/shared/layout-light";

import RTB from "../../components/shared/rtb";

import planningSvg from "../../images/design-services/planning.svg";
import servicesSvg from "../../images/design-services/services.svg";
import productsSvg from "../../images/design-services/products.svg";

import FeaturedInLogos from "../../components/design-services/featured-in";
import GetStarted from "../../components/design-services/get-started";

import Gallery from "../../components/design-services/gallery";
import ConsultSteps from "../../components/design-services/steps";
import TuneIn from "../../components/design-services/tune-in";
import { useFunnelTracking } from "../../utils/analytics";
import DesignCtas from "../../components/design-services/design-ctas";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "design-services/hero.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 1240) {
          ...GatsbyImageSharpSizes
        }
      }
    }

    steps: file(relativePath: { eq: "design-services/steps.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 790, maxHeight: 801) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const RTB_DATA = [
  {
    image: planningSvg,
    title: "Space Planning",
    text: "We'll help you select the best products to fit your space and lifestyle",
  },
  {
    image: servicesSvg,
    title: "Exterior Design",
    text: "From coastal modern to something more traditional, we’ll help you achieve your vision",
  },
  {
    image: productsSvg,
    title: "Product Questions",
    text: "We're standing by to answer any questions you have about Outer products",
  },
];

const DesignServicesPage = function ({ data }) {
  useFunnelTracking("Design Services");
  return (
    <div className="text-colors-outer-slate">
      <Layout homeUrl="https://liveouter.com">
        <Hero data={data} />
        <RTB data={RTB_DATA} />
        <div className="mt-5 w-9/12 mx-auto md:w-auto md:mt-16 lg:mt-24">
          <DesignCtas hideCaption={true} />
        </div>
        <ConsultSteps fluidImg={data.steps.childImageSharp.fluid} />
        <TuneIn />
        <Gallery />
        <GetStarted />
        <FeaturedInLogos />
      </Layout>
    </div>
  );
};

export default DesignServicesPage;
