import Img from "gatsby-image";
import * as React from "react";

import DesignCtas from "./design-ctas";

export default function Hero({ data }) {
  return (
    <div className="relative text-center text-colors-outer-slate lg:text-white-900">
      <Img
        className="darken-image"
        fluid={data.hero.childImageSharp.sizes}
        alt="Try an Outer Sofa at home"
      />

      <div className="mt-7 lg:mt-12 lg:absolute lg:-inset-0 lg:flex lg:flex-col">
        <div className="content-container">
          <h1 className="text-4xl lg:text-5xl xl:text-7xl lg:mt-12 2xl:mt-40">
            Free Exterior Design & Space
            <br className="hidden sm:block" /> Planning Services
          </h1>
          <div className="lg:w-7/12 lg:mx-auto">
            <p className="text-lg mt-4 leading-snug md:mt-7 lg:mb-12 lg:tracking-wide lg:text-2xl">
              Our team of professional exterior design experts are ready to help
              you unlock your vision.
            </p>
          </div>
          <div className="hidden mx-auto lg:block">
            <DesignCtas ctaLight={true} hideCaption={true} />
          </div>
          <div className="mt-8 w-9/12 mb-10 mx-auto md:w-auto md:mt-16 lg:hidden">
            <DesignCtas hideCaption={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
