import * as React from "react";
import Img from "gatsby-image";

import "./steps.scss";
import DesignCtas from "./design-ctas";

function Step({ title, stepIndex, description, last = false }) {
  return (
    <li className="pb-8 md:pb-16 relative">
      <div
        className={`border-l-1 ${
          last ? "border-transparent" : "border-colors-outer-slate"
        } tl-point`}
      />
      <h3 className="text-colors-outer-slate text-lg mb-3">Step {stepIndex}</h3>
      <h2 className="text-2xl md:text-4xl mb-4">{title}</h2>
      <p className="text-colors-outer-slate text-xl">{description}</p>
    </li>
  );
}

export default function ConsultSteps({ fluidImg }) {
  return (
    <div className="content-container-max mt-24 lg:mt-36">
      <div className="lg:flex">
        <div className="lg:w-5/12 xl:w-7/12 xl:pr-5">
          <Img fluid={fluidImg} alt="alt" />
        </div>
        <div className="mt-7 lg:pl-16 xl:w-5/12 xl:mt-0 flex items-center ">
          <div className="ml-12 lg:ml-9">
            <ul>
              <Step
                title="Get in touch"
                stepIndex={1}
                description="Request a consult with with our design team or chat in to get started"
              />

              <Step
                title="Tell us about your project"
                stepIndex={2}
                description="Share details about your outdoor space, your lifestyle, your vision, and challenges"
              />

              <Step
                title="Collaborate with a pro"
                stepIndex={3}
                last={true}
                description="An expert from our design team will work hand in hand with you to achieve your vision"
              />
            </ul>
            <div className="-ml-10">
              <DesignCtas altPrimaryText={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
